<template>
  <div class="mainwrap privacylist" v-loading="loading">
    <div class="title">
      {{title}}
    </div>
    <div class="searchform">
      <el-form
          label-width="82px"
          :inline="true"
          ref="detailFrom"
          class="demo-form-inline"
      >
       <el-form-item label="角色名稱：" >
           <el-input
              v-model="roles"
              placeholder="請輸入角色名稱"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="value" label="菜單名稱"> </el-table-column>
        <el-table-column label="權限">
            <template slot-scope="scope">
              <el-radio v-model="scope.row.permissionType" :label="0">不可見</el-radio>
              <el-radio v-model="scope.row.permissionType" :label="1">可見</el-radio>
              <el-radio v-model="scope.row.permissionType" :label="2">可維護</el-radio>
            </template>
        </el-table-column>
      </el-table>
    </div>

     <div class="btnwraps">
      <div class="btnlists">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./api";

export default {
  data(){
    return{
      loading: false,
      isSinglePage: false,
      currentPage: 1,
      tableDataTotal: 100,
      rolearr: [

      ],
      title:'',
      roles: '',
      roleid:'',
      radio: 1,
      curtableData:[],
      tableData: [],
      detailId:''
    }
  },
  created() {
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
      this.title = '編輯角色'
      this.detailId=queryObj.id
      this.getSingledata(this.detailId)

    } else if(queryObj.type && queryObj.type === "create") {
      this.title = '新增角色'
      this.getAllpermissions()
    }
  },
  methods:{
    // 查詢
    onSearch() {
      this.currentPage=1;
    },
    // 獲取所有角色
    getAllroles() {
      this.loading = true;
      api.getAllrole().then(res=>{
        if(res.systemCode===200){
          this.rolearr = res.data
        }
        this.loading = false;
      })
    },
    // 獲取所有權限
    getAllpermissions() {
      this.loading = true;
      api.getAllpermissions().then(res=>{
        if(res.systemCode===200){
          // let arr = res.data
          // if(this.detailId) {
          //   // 遍历所有权限，判断当前角色的key和code是否一样，是就赋值isGranted
          //   // 需要兩個數組的length相同才能用這個
          //   Object.keys(arr).forEach(key => {
          //       if (arr[key].key == this.curtableData[key].code) {
          //           arr[key].isGranted = this.curtableData[key].isGranted;
          //     }
          //   });
          // }
          this.tableData = res.data;
        }
        this.loading = false;
      })
    },
    // 獲取单条数据
    getSingledata(id) {
      this.loading = true;
      api.operationRoles(id).then(res=>{
        if(res.systemCode===200){
          this.roles = res.data.name
          this.roleid = res.data.id
          // this.curtableData = res.data.permissions
          res.data.permissions.forEach(item=> {
            item.value = item.name
          })
          this.tableData = res.data.permissions
        }
        this.loading = false;
      })
    },
    // 提交
    onSubmit() {
      let newtableData = []
        this.tableData.forEach(item=> {
          newtableData.push({
            code:item.key ? item.key : item.code,
            permissionType: item.permissionType,
            name: item.value
        })
      })
      let params={
        name:this.roles,
        permissions:newtableData
      }
      if(this.detailId){
        params.id=this.detailId
      }
      api.roleEdit(params).then(res=>{
        if(res.systemCode===200){
          this.$message.success('提交成功');
          this.$router.push({ path: "/cms/authoritymanagement" });
        }else{
          // this.$message.error(res.message);
        }
      })
      console.log("error submit!!");
      return false;
    },
     // 取消
    cancel() {
      this.$router.push({ path: "/cms/authoritymanagement" });
    },
  },
}
</script>

<style lang="scss" scoped>
.privacylist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }

  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
